<template>
  <div>
    <b-card
      id="pdfGenerateDiv"
      ref="pdfGenerateDivRef"
      no-body
      class="invoice-preview-card p-1 main-table"
    >
      <div>
        <div
          class="card__inner"
        >
          <table style="width: 100%; page-break-after: auto; border-collapse:separate; border-spacing: 10px 5px;">
            <thead>
              <tr>
                <td>
                  <!-- Header -->
                  <b-card-body class="invoice-padding pb-0">

                    <div class="d-flex justify-content-between flex-md-row invoice-spacing mt-0">
                      <div>
                        <div class="logo-wrapper">
                          <img
                            style="height:60px;object-fit:contain;"
                            :src="purchaseRequest.company ? (purchaseRequest.company.logo ? purchaseRequest.company.logo : '/nirvana-memorial-garden-logo.png') : '/nirvana-memorial-garden-logo.png'"
                            alt="Logo"
                            crossorigin="anonymous"
                          >
                        </div>
                      </div>
                      <div
                        style="min-width: fit-content; width: fit-content;"
                        class="px-2"
                      >
                        <h4 style="text-align: center; text-transform: uppercase; color: #000000; font-weight: bold; font-size: 12px; line-height: 18px;">
                          Purchase Order
                        </h4>
                      </div>
                      <div class="mt-md-0 mt-2 text-right">
                        <h4
                          class="invoice-title"
                          style="font-size: 12px;line-height: 21px;color: #104D9D; margin:0; font-weight: bold"
                        >
                          {{ purchaseRequest.caseID }}
                        </h4>
                        <div class="invoice-date-wrapper">
                          <p
                            class="invoice-date"
                            style="font-weight: normal !important;font-size: 11px;line-height: 16px; margin:0;"
                          >
                            <span
                              class="semi-bold"
                              style="font-weight: bold;"
                            >PR / CAPEX No.:</span> {{ purchaseRequest.stringID }}
                          </p>
                        </div>
                        <div class="invoice-date-wrapper">
                          <p
                            class="invoice-date"
                            style="font-weight: normal !important;font-size: 11px;line-height: 16px; margin:0;"
                          >
                            <span
                              class="semi-bold"
                              style="font-weight: bold;"
                            >Created On:</span> {{ dateFormatWithTime(pOrder.createdAt) }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </b-card-body>

                  <!-- Spacer -->
                  <hr
                    class="invoice-spacing"
                    style="margin-top:25px!important"
                  >
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>

                  <!-- Invoice Client & Payment Details -->
                  <b-card-body
                    class="invoice-padding pt-2 main__body po__create preview_order_pdf"
                  >
                    <b-row>
                      <b-col cols="md-6">
                        <b-table-simple
                          borderless
                          responsive
                        >
                          <b-tbody>
                            <b-tr>
                              <b-th
                                width="230px"
                                style="font-weight: bold;"
                              >
                                Order From
                              </b-th>
                              <b-td>
                                <span
                                  class="text-bold-black"
                                >{{ pOrder.vendorName }}</span>
                                <br>
                                <span class="text-pre">{{ pOrder.vendorAddress }}</span>
                              </b-td>
                            </b-tr>
                            <b-tr>
                              <b-th
                                width="230px"
                                style="font-weight: bold;"
                              >
                                Vendor Tel
                              </b-th>
                              <b-td>{{ pOrder.companyContact ? pOrder.companyContact : '-' }}</b-td>
                            </b-tr>
                            <b-tr>
                              <b-th
                                width="230px"
                                style="font-weight: bold;"
                              >
                                Vendor Fax
                              </b-th>
                              <b-td>{{ pOrder.companyFax ? pOrder.companyFax : '-' }}</b-td>
                            </b-tr>
                            <b-tr>
                              <b-th
                                width="230px"
                                style="font-weight: bold;"
                              >
                                Vendor H/P
                              </b-th>
                              <b-td>{{ pOrder.companyHP ? pOrder.companyHP : '-' }}</b-td>
                            </b-tr>
                            <b-tr>
                              <b-th
                                width="230px"
                                style="font-weight: bold;"
                              >
                                Vendor Email
                              </b-th>
                              <b-td>{{ pOrder.companyEmail ? pOrder.companyEmail : '-' }}</b-td>
                            </b-tr>
                            <b-tr>
                              <b-th
                                width="230px"
                                style="font-weight: bold;"
                              >
                                Vendor Contact Person
                              </b-th>
                              <b-td>{{ pOrder.salesPersonName ? pOrder.salesPersonName : '-' }}</b-td>
                            </b-tr>
                            <b-tr>
                              <b-th
                                width="230px"
                                style="font-weight: bold;"
                              >
                                Payment Terms
                              </b-th>
                              <b-td>{{ pOrder.paymentTerms ? pOrder.paymentTerms : '-' }}</b-td>
                            </b-tr>
                            <b-tr>
                              <b-th
                                width="230px"
                                style="font-weight: bold;"
                              >
                                Delivery Lead Time
                              </b-th>
                              <b-td>{{ pOrder.deliveryLeadTime ? pOrder.deliveryLeadTime : '-' }}</b-td>
                            </b-tr>
                          </b-tbody>
                        </b-table-simple>
                      </b-col>
                      <b-col cols="md-6">
                        <b-table-simple
                          borderless
                          responsive
                        >
                          <b-tbody>
                            <b-tr>
                              <b-th
                                width="230px"
                                style="font-weight: bold;"
                              >
                                Deliver To
                              </b-th>
                              <b-td>
                                <span
                                  class="text-bold-black"
                                >{{ pOrder.deliverTo ? pOrder.deliverTo.name : '' }}</span>
                                <br>
                                <span class="text-pre">{{ pOrder.address }}</span>
                              </b-td>
                            </b-tr>
                            <b-tr>
                              <b-th width="230px">
                                Delivery Info
                              </b-th>
                              <b-td><span class="text-pre">{{ pOrder.deliveryInfo || '-' }}</span></b-td>
                            </b-tr>
                            <b-tr>
                              <b-th
                                width="230px"
                                style="font-weight: bold;"
                              >
                                Contact Person
                              </b-th>
                              <b-td>{{ pOrder.mainContactPerson ? pOrder.mainContactPerson : '-' }}{{ pOrder.secondContactPerson ? `, ${pOrder.secondContactPerson}` : '' }}</b-td>
                            </b-tr>
                            <b-tr>
                              <b-th
                                width="230px"
                                style="font-weight: bold;"
                              >
                                Tel
                              </b-th>
                              <b-td>{{ pOrder.mainContactPersonContact ? pOrder.mainContactPersonContact : '-' }}</b-td>
                            </b-tr>
                            <b-tr>
                              <b-th
                                width="230px"
                                style="font-weight: bold;"
                              >
                                Mobile
                              </b-th>
                              <b-td>{{ pOrder.backupContact ? pOrder.backupContact : '-' }} ({{ pOrder.secondContactPerson ? pOrder.secondContactPerson : '-' }})</b-td>
                            </b-tr>
                            <b-tr>
                              <b-th
                                width="230px"
                                style="font-weight: bold;"
                              >
                                Fax
                              </b-th>
                              <b-td>{{ pOrder.contactFax ? pOrder.contactFax : '-' }}</b-td>
                            </b-tr>
                            <b-tr>
                              <b-th
                                width="230px"
                                style="font-weight: bold;"
                              >
                                Email
                              </b-th>
                              <b-td>{{ pOrder.contactEmail ? pOrder.contactEmail : '-' }}</b-td>
                            </b-tr>
                          </b-tbody>
                        </b-table-simple>
                      </b-col>
                    </b-row>
                    <b-row v-if="poItems.length">
                      <b-col
                        cols="12"
                        class="item__description_table"
                      >
                        <!-- Invoice Description: Table -->
                        <b-table-simple
                          ref="teamsTable"
                          class="position-relative has_padding"
                          responsive
                          caption-top
                        >
                          <b-thead>
                            <b-tr class="group__tag">
                              <b-th>
                                No
                              </b-th>
                              <b-th>
                                Description
                              </b-th>
                              <b-th>
                                Unit
                              </b-th>
                              <b-th>
                                Qty Ordered
                              </b-th>
                              <b-th>
                                Unit Cost ($)
                              </b-th>
                              <b-th>
                                Amount ($)
                              </b-th>
                            </b-tr>
                          </b-thead>
                          <b-tbody
                            class="white-body-background"
                          >
                            <b-tr
                              v-for="(item, index) in poItems"
                              :key="index"
                            >
                              <b-td>
                                {{ index + 1 }}
                              </b-td>
                              <b-td style="max-width: 50rem;">
                                <span
                                  class="text-bold semi-bold"
                                  style=""
                                >{{ item.itemName }}</span>
                                <br>
                                <span class="text-pre">{{ item.itemDescription }}</span>
                              </b-td>
                              <td>
                                {{ item.unit }}
                              </td>
                              <b-td>
                                {{ item.quantity }}
                              </b-td>
                              <td>
                                {{ item.unitCost }}
                              </td>
                              <td>
                                {{ numberFormat(item.amount) }}
                              </td>
                            </b-tr>
                            <tr
                              class="row__has_bg has_border"
                            >
                              <td colspan="4" />
                              <td
                                colspan=""
                                style="font-weight: bold"
                              >
                                Subtotal
                              </td>
                              <td>
                                {{ pOrder.subTotal ? numberFormat(pOrder.subTotal) : 0.00 }}
                              </td>
                            </tr>
                            <tr
                              class="row__has_bg"
                            >
                              <td colspan="4" />
                              <td style="font-weight: bold">
                                GST {{ pOrder.gstPercentage }}%
                              </td>
                              <td>
                                {{ pOrder.gst == '-' ? '-' : (pOrder.gst ? numberFormat(pOrder.gst) : 0.00) }}
                              </td>
                            </tr>
                            <tr
                              class="row__has_bg last_child"
                            >
                              <td colspan="4" />
                              <td>
                                <strong style="font-weight: bold">Grand Total</strong>
                              </td>
                              <td>
                                <strong style="font-weight: bold">{{ pOrder.grandTotal ? numberFormat(pOrder.grandTotal) : 0.00 }}</strong>
                              </td>
                            </tr>
                          </b-tbody>
                        </b-table-simple>
                      </b-col>
                    </b-row>
                  </b-card-body>
                </td>
              </tr>
              <tr
                style="
                  width: 100%; page-break-before: auto;
                  page-break-after: auto;
                  page-break-inside: avoid;
                "
                class="footer-class-a"
              >
                <td>

                  <div>
                    <div :style="{ height: gapHeight+'mm' }" />
                    <!-- Spacer -->
                    <hr class="invoice-spacing">

                    <!-- Invoice Description: Total -->
                    <b-card-body class="invoice-padding pb-0 footer__area">
                      <b-row>

                        <!-- Col: Sales Persion -->
                        <b-col
                          v-for="(signatory, index) of pOrder.signatories"
                          :key="index"
                          cols="3"
                          md="3"
                          class="mt-md-1 mt-1"
                          order="2"
                          order-md="1"
                        >
                          <b-card-text class="mb-0">
                            <span class="font-weight-bold">{{ index + 1 }}. {{ signatory.type }}</span>
                            <div class="sign-box">
                              <div v-if="signatory.signed == true">
                                <div v-if="signatory.user && signatory.user.signature">
                                  <b-img
                                    class="mb-1 mb-sm-0 user__avatar"
                                    height="40"
                                    crossorigin="anonymous"
                                    style="height:40px; width: 90%; object-fit:contain;"
                                    :src="signatory.user.signature"
                                  />
                                </div>
                                <div v-else-if="signatory.user && signatory.user.name">
                                  <span>{{ signatory.user.name }}</span>
                                </div>
                              </div>
                              <div v-else>
                                <p>Pending Signature</p>
                              </div>
                            </div>
                            <p
                              class="ml-75 semi-bold"
                              style="font-weight: bold;"
                            >
                              {{ signatory.user ? signatory.user.name : '' }}
                            </p>
                            <p class="ml-75">
                              {{ signatory.user ? (signatory.user.department.length ? signatory.user.department[0].name : '') : '' }}
                            </p>
                            <p class="ml-75">
                              {{ signatory.signed == true ? dateFormatWithTime(signatory.date) : '-' }}
                            </p>
                          </b-card-text>
                        </b-col>
                      </b-row>
                    </b-card-body>
                    <b-card-body class="invoice-padding pb-0 terms__block">
                      <b-row
                        class="content-header"
                      >

                        <!-- Content Left -->
                        <b-col
                          cols="12"
                          md="12"
                        >
                          <h4>Terms and Conditions</h4>
                          <b-card-text class="mb-0">
                            <p>1. Acknowledgement copy of this order form must be stamped, signed and returned to us for confirmation of acceptance forthwith.</p>
                            <p>2. INVOICE IN DUPLICATE indicating Delivery Note number and date of delivery.</p>
                            <p>3. Credit terms begin at date of receipt of invoice or good whichever is later.</p>
                            <p>4. A higher price than shown on the order must not be charged without our authority.</p>
                            <p>5. Please execute out for goods listed above forthwith. This right is reserved to cancel this order if goods are not supplied within that stipulated above. Goods supplied not in accordance with our specifications will be returned at your own risk and expense.</p>
                            <p>6. We do not hole ourselves responsible for any goods delivered unless a written order duly signed by the authorised official our company can be produced. Suppliers should therefore in their own interest see that such order is obtained before executing order.</p>
                            <p>7. Please email or call us when deliver. Kindly note that our delivery time is from 8:30am - 11:00am or 1pm - 4pm, Monday - Friday excluding Public Holiday.</p>
                          </b-card-text>
                        </b-col>
                      </b-row>
                    </b-card-body>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BImg, BCardText, BTableSimple, BTbody, BThead,
  BTr, BTh, BTd, BCardBody,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BImg,
    BCardText,
    BTableSimple,
    BTbody,
    BThead,
    BTr,
    BTh,
    BTd,
    BCardBody,
  },
  data() {
    return {
      elHeight: 0,
      gapHeight: 1,
      purchaseRequest: {},
      pOrder: {},
      poItems: [],
    }
  },
  mounted() {
    this.$http.get(`download/purchase-order/${this.$route.params.id}/show`)
      .then(response => {
        this.purchaseRequest = response.data.purchaseRequest || {}
        this.pOrder = response.data.purchaseOrder || {}
        this.poItems = response.data.poItems || []
      })
  },
  methods: {
    resolveItemCounter(page, index) {
      let count = 0
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < (page - 1); i++) {
        count += this.chunksOfItemRequests[i].length
      }

      count += index + 1

      return count
    },
  },
}
</script>

  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
  </style>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
  <style lang="scss">
    body div#app.semi-dark-layout {
        background: #fff !important;
        background-repeat: no-repeat !important;
        background-position: center bottom !important;
        background-size: 100% auto !important;
    }
    body {
        background: #fff !important;
    }
    .card {
        box-shadow: none !important;
    }
  </style>
  <style>
    @media print {
        html, body {
            height: 99%;
        }
    }
  </style>
